import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f4a6ba20&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=f4a6ba20&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Slides: require('/var/www/pb-store/components/Slides.vue').default,CategoriesGrid: require('/var/www/pb-store/components/CategoriesGrid.vue').default,CategorySlide: require('/var/www/pb-store/components/CategorySlide.vue').default,ContactForm: require('/var/www/pb-store/components/ContactForm.vue').default})
