
export default {
  name: 'ContactForm',
  data () {
    return {
      loading: false,
      contact: {
        fullname: '',
        phone: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    trigger () {
      this.$notify.show({
        text: 'You will be contact in a few minutes',
        type: 'success',
        closeWait: 2000
      })
    },
    sendContact () {
      this.loading = true
      const qs = require('querystring')
      const header = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      const tr = this
      this.$axios.post('/contact', qs.stringify(this.contact), header)
        .then(function (res) {
          tr.loading = false
          if (res.data.success) {
            tr.$notify.show({
              text: 'En las próximas horas será contactado por uno de nuestros agentes de servicio',
              type: 'success',
              closeWait: 3000
            })
            setTimeout(() => {
              tr.$router.push({ name: 'index' })
            }, 2000)
          } else {
            tr.$notify.show({
              text: 'Error enviando los datos, intente mas tarde',
              type: 'danger',
              closeWait: 2000
            })
          }
        })
        .catch(function (error) {
          tr.$notify.show({
            text: error,
            type: 'danger',
            closeWait: 5000
          })
        })
    }
  }
}
