
// import '~/plugins/CardGroup.js'
export default {
  name: 'Slides',
  data () {
    return {
      reload: true,
      mainSlides: null,
      mainSlidesGuides: null,
      scrollLeft: null,
      slides: [
        {
          title: process.env.STORE_NAME,
          text: 'We are a wholesale company of products for artficial grass in Florida',
          img: 's1.png'
        },
        {
          title: 'We have all you need',
          text: 'Glue, Nails, infill and more',
          img: 's2.png'
        },
        {
          title: 'Exclusive products',
          text: 'The most beautiful and dense artificial ivy',
          img: 's3.png'
        }
      ]
    }
  },
  mounted () {
    this.mainSlides = this.$el.querySelector('.mainSlides .con-slides')
    this.mainSlidesGuides = this.$el.querySelector('.mainSlides .slides-guides')
    this.mainSlidesCarousel()
  },
  methods: {
    handleScrollPrev () {
      const slides = this.mainSlides
      this.scrollLeft = slides.scrollLeft === slides.scrollWidth - slides.offsetWidth ? 0 : slides.scrollLeft + slides.offsetWidth
      slides.scrollLeft = this.scrollLeft
      this.checkCurrentActiveSlide()
      this.reload = false
    },
    handleScrollNext () {
      const slides = this.mainSlides
      this.scrollLeft = slides.scrollLeft === 0 ? slides.scrollWidth - slides.offsetWidth : slides.scrollLeft - slides.offsetWidth
      slides.scrollLeft = this.scrollLeft
      this.checkCurrentActiveSlide()
      this.reload = false
    },
    showMainSlide (ind) {
      this.scrollLeft = ind * this.mainSlides.offsetWidth
      this.mainSlides.scrollLeft = this.scrollLeft
      this.checkCurrentActiveSlide()
      this.reload = false
    },
    mainSlidesCarousel () {
      const slides = this.mainSlides
      setInterval(() => {
        if (this.reload) {
          this.scrollLeft = slides.scrollLeft === slides.scrollWidth - slides.offsetWidth ? 0 : slides.scrollLeft + slides.offsetWidth
          slides.scrollLeft = this.scrollLeft
          this.checkCurrentActiveSlide()
        }
      }, 5000)
    },
    updateScrollLeft () {
      this.scrollLeft = this.mainSlides.scrollLeft
    },
    checkCurrentActiveSlide () {
      if (window.innerWidth < 500) {
        this.scrollLeft = this.mainSlides.scrollLeft
      }
      const ind = Math.round(this.scrollLeft / this.mainSlides.offsetWidth)
      Array.from(this.mainSlidesGuides.children).forEach((elm) => {
        elm.classList.remove('active')
      })
      if (!isNaN(ind) && ind < this.mainSlidesGuides.childElementCount) {
        this.mainSlidesGuides.children[ind].classList.add('active')
      }
    }
  }
}
